window.useEventTracking = function (event) {
    console.log(event);

    window.dataLayer = window.dataLayer || [];

    if (typeof event === "object") {
        window.dataLayer.push(event);
    } else {
        window.dataLayer.push({ event });
    }
};
